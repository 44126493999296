import React from 'react'

const Hero = ({ title }) => {

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-1">{title}</h1>
        </div>
      </div>
    </section>
  )
}

export default Hero