import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, Link, graphql } from "gatsby"

import NavbarMenuItem from "./navbarmenuitem.js"

class Header extends React.Component {

  state = {
    activeMenu: false,
  }

  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    })
  }

  render() {
    return (
      <section className="nav">
        <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" aria-label="Home page"><Image /></Link>
              <a role="button" onClick={this.toggleMenu} className={`navbar-burger burger ${this.state.activeMenu ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div className={`navbar-menu ${this.state.activeMenu ? 'is-active' : ''}`}>
              <div className="navbar-end">
                <NavbarMenuItem page="/" pagename="Home"></NavbarMenuItem>
                <NavbarMenuItem page="/about/" pagename="About"></NavbarMenuItem>
                <NavbarMenuItem page="/events/" pagename="Events"></NavbarMenuItem>
                <NavbarMenuItem page="/events/ecso2007" pagename="ECSO 2007"></NavbarMenuItem>
                <NavbarMenuItem page="/join/" pagename="Join"></NavbarMenuItem>
                <NavbarMenuItem page="/contact/" pagename="Contact"></NavbarMenuItem>
              </div>
            </div>
          </div>
        </nav>
      </section>
    )
  }
}

export default Header

const Image = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "images/ISSlogo.png" }) {
          childImageSharp {
            fixed(height: 64) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Img fixed={logo.childImageSharp.fixed} style={{display:'block'}} alt="Irish Skeptics Society logo"/>
  )
}