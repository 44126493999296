import React from "react"
import { Link } from "gatsby"

import styles from "./navbarmenuitem.module.scss"

const NavbarMenuItem = ({ pagename, page }) => (
  <Link className={`navbar-item ${styles.navbarItem}`} activeClassName={styles.isActive}
        aria-label={`${pagename} page`} to={page}>
    {pagename}
  </Link>
)

export default NavbarMenuItem
