import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Hero from "../components/hero"

export default ({ title, children }) => (
  <div className="is-full-height">
    <Header />
    <Hero title={title} />
    <section className="section main">
      <div className="container">
        {children}
      </div>
    </section>
    <Footer />
  </div>
)